import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const About = () => {
  const arrayLeft = "[";
  const arrayRight = "]";

  const [currentPic, setCurrentPic] = useState(1);

  useEffect(() => {
    document.title = "About me - Alexis PRIVAT";
    const interval = setInterval(() => {
      setCurrentPic((currentPic) => (currentPic === 1 ? 2 : 1));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="aboutPage">
      <h2 className="pageTitle">
        ABOUT
        <span className="pointTitle nexttitle nexttitleAbout">{arrayLeft}</span>
        ME
        <span className="pointTitle nexttitle nexttitleAbout">
          {arrayRight}
        </span>
      </h2>
      <div className="aboutPageContainer">
        <div className="aboutPageLeft">
          {currentPic === 1 ? (
            <img
              className="profilPicOne"
              src="../profil_pic.png"
              alt="profile "
            />
          ) : (
            <img
              className="profilPicTwo"
              src="../profil_pic2.png"
              alt="profile "
            />
          )}
        </div>
        <div className="aboutPageRight">
          <p>
            <span className="aboutPageHi">Hi !</span>
            <br />
            <br />
            My name is Alexis Privat.
            <br />
            <br />
            A few years ago, I decided to turn my passion into a profession by
            becoming a web developer. I first completed a training course to
            refresh my knowledge of HTML, CSS, and JavaScript, and then I
            specialized in JavaScript and ReactJS through a second training
            program. <br />
            <br />
            To gain my first professional experience, I completed a year of
            apprenticeship at{" "}
            <a
              className="aboutPageResume"
              href="https://www.glimps.re/"
              target="blank"
            >
              GLIMPS
            </a>
            , a cybersecurity company in Rennes, where I was responsible for the
            role of front-end developer. My tasks included choosing and carrying
            out the tasks previously assessed during the Scrum ceremonies.
            During this last year, I primarily worked with{" "}
            <span className="aboutPageSentenceFormation">Angular</span> and{" "}
            <span className="aboutPageSentenceFormation">TypeScript</span>. It
            was very enriching to discover the product lifecycle, from the
            mock-up phase to integration, continuous integration testing,
            creating the Docker image, and finally deploying on Kubernetes pods.
            <br />
            <br />
            <span className="aboutPageSentence">
              Since September 2024, I have returned to Bordeaux and am actively
              seeking a job.
            </span>
            <br />
            <br />
            You can find my resume{" "}
            <Link to="../CV.pdf" target="_blank">
              <span className="aboutPageResume">here.</span>
            </Link>
            <br />
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
