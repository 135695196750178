import React, { useEffect, useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const Project = () => {
  const [open, setOpen] = useState(false);
  const [project, setProject] = useState(null);
  const projects = [
    {
      title: "Kasa",
      mission: "Integration of a Figma mockup in React",
      outils: [{ name: "React" }, { name: "scss" }],
      link: "",
      pic: [
        { src: "../kasa1.png" },
        { src: "../kasa2.png" },
        { src: "../kasa3.png" },
      ],
    },
    {
      title: "Champions",
      mission:
        "This was the project for the technical interview I had for the company where I did my apprenticeship. The goal was to interact with the League of Legends API and display the free heroes of the week.",
      outils: [{ name: "Angular" }, { name: "RXJS" }, { name: "Typescript" }],
      link: "",
      pic: [
        { src: "../lol1.png" },
        { src: "../lol2.png" },
        { src: "../lol3.png" },
        { src: "../lol4.png" },
      ],
    },
    {
      title: "Billed",
      mission:
        "Bugs identified through a kanban were corrected, unit testing was conducted using jestjs, and an end-to-end testing plan was created.",
      outils: [{ name: "javascript" }, { name: "jestjs" }, { name: "notion" }],
      link: "",
      pic: [
        { src: "../billed4.png" },
        { src: "../billed2.png" },
        { src: "../billed3.png" },
      ],
    },
    {
      title: "Sportsee",
      mission: "Replicate a Figma mockup using React and Recharts library",
      outils: [
        { name: "React" },
        { name: "react-router-dom" },
        { name: "Recharts" },
      ],
      link: "",
      pic: [{ src: "../sportsee.png" }],
    },
    {
      title: "ohmyfood",
      mission: "Creation of SCSS animations based on a design mockup.",
      outils: [{ name: "html" }, { name: "sass" }],
      link: "",
      pic: [
        { src: "../ohmyfood1.png" },
        { src: "../ohmyfood2.png" },
        { src: "../ohmyfood3.png" },
      ],
    },
    {
      title: "Mdd",
      mission:
        "This is a full stack Java/Angular project. The goal was to create the API in Java and document it using Swagger, and to build a front-end in Angular. The application allows users to subscribe to a theme, write an article on that theme, and comment on it.",
      outils: [
        { name: "Angular 17" },
        { name: "RXJS" },
        { name: "JAVA" },
        { name: "Typescript" },
      ],
      link: "",
      pic: [
        { src: "../mdd1.png" },
        { src: "../mdd2.png" },
        { src: "../mdd3.png" },
        { src: "../mdd4.png" },
      ],
    },

    {
      title: "Télésport",
      mission:
        "This is one of my first projects in Angular, and it was my first experience with RxJS. The task was to display graphical data using the D3 library.",
      outils: [
        { name: "Angular" },
        { name: "Typescript" },
        { name: "Rxjs" },
        { name: "D3JS" },
      ],
      link: "",
      pic: [{ src: "../telesport1.png" }, { src: "../telesport2.png" }],
    },
    {
      title: "les petits plats",
      mission:
        "Two search algorithms were created with multiple filters, implemented through the responsibility pattern, with one version using native loops and another using functional programming with array methods. The performance of both versions was then tested.",
      outils: [{ name: "javascript" }, { name: "html" }, { name: "css" }],
      link: "",
      pic: [
        { src: "../lespetitsplats1.png" },
        { src: "../lespetitsplats2.png" },
        { src: "../lespetitsplats3.png" },
      ],
    },

    {
      title: "groupomania",
      mission:
        "Create a secure enterprise social network with authentication/token. Manage profiles, allow users to post content on the platform, follow specific users, and more.",
      outils: [
        { name: "reactjs" },
        { name: "nodejs" },
        { name: "redux" },
        { name: "sass" },
        { name: "mongoDB" },
      ],
      link: "",
      pic: [
        { src: "../groupomania1.png" },
        { src: "../groupomania2.png" },
        { src: "../groupomania3.png" },
      ],
    },

    {
      title: "gameon",
      mission:
        "Multiple bugs were fixed, and an registration modal was implemented.",
      outils: [{ name: "html" }, { name: "css" }, { name: "javascript" }],
      link: "",
      pic: [
        { src: "../gameon1.png" },
        { src: "../gameon2.png" },
        { src: "../gameon3.png" },
      ],
    },
    {
      title: "fisheye",
      mission:
        "Create an accessible website for a platform of photographers. Ensure logical navigation with tab support and use of aria-label tags.",
      outils: [{ name: "html" }, { name: "css" }, { name: "javascript" }],
      link: "",
      pic: [
        { src: "../fisheye1.png" },
        { src: "../fisheye2.png" },
        { src: "../fisheye3.png" },
      ],
    },
  ];

  useEffect(() => {
    document.title = "Portfolio - Alexis PRIVAT";
  }, []);

  return (
    <div className="projectPage">
      <h2 className="pageTitle">PORTFOLIO</h2>
      <span className="pointTitle nexttitle">.</span>
      <div className="projectContainer">
        <ul>
          {projects.map((project, index) => (
            <li
              key={index}
              onClick={() => {
                setProject(project);
                setOpen(true);
              }}
            >
              <div className="projectLeftPart">
                <div className="projectContent">
                  <p className="projectTitle">{project.title}</p>
                  <p className="projectMission">{project.mission}</p>
                  <div className="outilsCardContainer">
                    {project.outils.map((outil, index) => (
                      <div className="outilCard" key={index}>
                        <p>{outil.name}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                  fill="#ffff"
                />
              </svg>
            </li>
          ))}
        </ul>
      </div>
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={project?.pic || []}
      />
    </div>
  );
};

export default Project;
